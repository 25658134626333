import React from "react";
import Layout from "../components/layout";
import { Contact } from "../components/sections";

const ContactUs = () => {
  return (
    <Layout>
      <div className="relative py-16 bg-white overflow-hidden">
        <h1 className="block  text-center text-customBlue font-semibold tracking-wide uppercase">
          Contact Us
        </h1>
        <Contact />
      </div>
    </Layout>
  );
};

export default ContactUs;
